import { useLocation, useParams } from "react-router-dom";
import { Chart } from "../../../../components/Chart";
import { Button } from "../../../../components/Button";
import { getCarData } from "../../api/getCarData";
import { useCallback, useMemo } from "react";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { CarDataResponse, DataItem } from "../../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../../constants";
import { getReport } from "../../api/getReport";

import styles from "./chartWrapper.module.sass";

const getParseChartData = (item: DataItem) => {
  return {
    title: item?.name,
    series: item?.items?.map((el) => {
      return {
        name: el?.name,
        data: el.data?.map((x) => {
          return [x.dateTime, x.value];
        }),
        animation: false,
        type: "line",
        smooth: false,
        id: el?.name,
        itemStyle: {
          color: `#${el.color}`,
        },
        yAxis: {
          type: "value",
        },
        markLine: {
          silent: true,
          lineStyle: {
            opacity: item?.items?.length === 1 ? 1 : 0,
            color: `#FF0000`,
          },
          data: el.thresholds?.map((treeshold) => ({
            name: treeshold.name,
            yAxis: treeshold.value,
          })),
          animation: false,
        },
      };
    }),
  };
};

type ChartWrapperProps = {
  dates?: {
    startDate: string;
    endDate: string;
  };
};

export const ChartWrapper = ({ dates }: ChartWrapperProps) => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  const { id: carId } = useParams<{ id: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tripId = searchParams.get("tripId") || undefined;
  const hostId = searchParams.get("hostId") || undefined;

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery<
    CarDataResponse | undefined
  >({
    queryKey: [
      "carData",
      tripId,
      carId,
      hostId,
      dates?.startDate,
      dates?.endDate,
      token,
    ],
    queryFn: ({ pageParam }) =>
      getCarData({
        tripId,
        carId,
        hostId,
        page: pageParam as number,
        beginDateTime: dates?.startDate,
        endDateTime: dates?.endDate,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage) return;
      return lastPage?.total !==
        allPages?.reduce((acc, el) => {
          if (!el?.data) return acc;
          return acc + el?.data?.length;
        }, 0)
        ? lastPage?.page + 1
        : undefined;
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  const carData = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...(page?.data ?? [])];
    }, [] as DataItem[]);
  }, [data]);

  const handleDownload = useCallback(
    async (graphId: number, graphItemIds: number[]) => {
      await getReport({
        graphId,
        graphItemIds,
        tripId: tripId,
        hostId: hostId,
        beginDateTime: dates?.startDate,
        endDateTime: dates?.endDate,
        carriageNumber: carId,
      });
    },
    [dates?.startDate, dates?.endDate, tripId, hostId]
  );

  return (
    <>
      {carData
        ? carData.map((el, idx) => {
            const parseData = el ? getParseChartData(el) : undefined;
            const legends = el?.items.map((el) => ({
              id: el.id,
              name: el?.name,
            }));

            return parseData ? (
              <div className={styles.chartWrapper} key={idx}>
                <Chart
                  handleDownload={handleDownload}
                  title={parseData?.title}
                  series={parseData?.series}
                  graphId={el?.id}
                  legends={legends}
                />
              </div>
            ) : undefined;
          })
        : undefined}
      {hasNextPage && (
        <div className={styles.showMore}>
          <Button
            disabled={isFetching}
            variant="contained"
            sx={{
              background: "rgba(61, 92, 221, 1)",
              height: "40px",
              textTransform: "capitalize",
              fontFamily: "Raleway",
              fontSize: "14px",
              color: "#fff",
              boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
              borderRadius: "4px",
            }}
            onClick={() => fetchNextPage()}
          >
            Показать еще
          </Button>
        </div>
      )}
    </>
  );
};
